import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/hero/hero.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["LottiePlayer"] */ "/app/src/components/common/lottie/player.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/landing-globe.jsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/landing/solutions.jsx");
